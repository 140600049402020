import React, { useEffect, useState } from 'react'
import Chatbot from 'react-chatbot-kit'
import 'react-chatbot-kit/build/main.css'
import './App.css';
import config from './bot/config.js';
import MessageParser from './bot/MessageParser.js';
import ActionProvider from './bot/ActionProvider.js';
import { getPicture, getProfile, uploadPictureD_id, createfollowingChat, createOrganization, getHairDresser, discussWithChat, startChat } from "./api/service";
import { appLanguage, titleNormalized } from "./select";
import { Langage, LangageHair, AppName } from "./language";
import { getSummary } from './api/service-hair.js';
import { resetLocalStorageKeys } from './utils/helper.js';
import Welcome from './components/welcome/welcome.jsx';
import actionProvider from "./bot/ActionProvider.js";

function App() {

  const [idOrganization, setIdOrganization] = useState(null);
  const [advisor, setAdvisor] = useState('');
  const [data, setData] = useState(null);
  const [chatReady, setChatReady] = useState(false);

  const handleChangeAdvisor = async (advisor) => {
    if(!localStorage.getItem('followingCactusId')) {
      setAdvisor(advisor);
      localStorage.setItem('advisor', advisor);
      let res = await createfollowingChat({ galien_id: idOrganization, chat_id: null, advisor });
      localStorage.setItem('followingCactusId', res.id);
    }
  }

  const handleReady = (flag) => {
    setChatReady(flag)
  }

  const getText = (key) => {
    return Langage[key][appLanguage];
  }
  const getTextApp = (key) => {
    return AppName[key][appLanguage];
  }

  const getAppName = (app) => {
    switch (app) {
      case 'chatbot-hair': return getTextApp("chatbot_hair");
      case 'chatbot-hair-ybera': return 'Confidences - Le salon';
      default: return 'Cactus';
    }
  }

  useEffect(() => {
    //document.querySelector('.react-chatbot-kit-chat-input').type = 'tel';

    const fetchData = async (id) => {
      try {
        let data = await getHairDresser(localStorage.getItem('organizationId'));
        if (!data || !data.length || data.length === 1) {
          let res = await createfollowingChat({ galien_id: id, chat_id: null, advisor: ((!data || !data.length) ? null : data[0].lastname) });
          localStorage.setItem('followingCactusId', res.id);
          localStorage.setItem('advisor', advisor);
        }
        setData(data);
      } catch (error) {
        console.error('Error fetching hairdresser data:', error);
      }
    }

    const fetchData2 = async () => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      let id = urlParams.get('id')
      let advisor = urlParams.get('advisor');
      let data
      if (!id)
        id = titleNormalized.includes('ybera') ? '2094429' : '2085475';
      try {
        const number = parseInt(id, 10);
        data = await createOrganization({ galien_user_id: number, app: window.location.hostname.split('.')[0], group_id: 4 })
        localStorage.setItem('organizationId', data.id);
        setIdOrganization(id)

        if (advisor) {
          let res = await createfollowingChat({ galien_id: id, chat_id: null, advisor });
          localStorage.setItem('followingCactusId', res.id);
          localStorage.setItem('advisor', advisor);
        }
        else if (!advisor) {
          await fetchData(id)
        }

      } catch (error) {
        console.error(error)
      }

    }

    fetchData2()
  }, [])

  // useEffect(() => {

  //   const fetchData2 = async () => {
  //     const queryString = window.location.search;
  //     const urlParams = new URLSearchParams(queryString);
  //     let id = urlParams.get('id')
  //     let data
  //     if(!id)
  //       id = '2085475'
  //     try {
  //         const number = parseInt(id, 10);
  //         data = await createOrganization({ galien_user_id: number, app: window.location.hostname.split('.')[0], group_id: 4 })
  //         setIdOrganization(data.id)
  //         let res = await createfollowingChat({ galien_id: number, chat_id: null, advisor });
  //         localStorage.setItem('followingCactusId', res.id);
  //     } catch (error) {
  //       console.error(error)
  //     }

  //   }

  //   fetchData2()
  // }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        let fromStart = false
        const toRemove = ['chatIdHair', 'followingCactusId', 'advisor', 'chatIdFace']
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        fromStart = urlParams.get('Start') === '1';

        if (fromStart) {
          toRemove.push('chatIdInfos')
        }
        resetLocalStorageKeys(toRemove)
        const getIdChat = async () => {
          try {
            let selection
            if (appLanguage === 'english')
              titleNormalized.includes('hair') ? selection = ["first_name", "email", "gender"] : selection = ["first_name", "email", "age", "gender"]
            else {
              titleNormalized.includes('hair') ? selection = ["prenom", "email", 'genre'] : selection = ["prenom", "email", "age", "genre"]
            }
            const data = await startChat('infos', { lang: appLanguage.slice(0, 2) }, { selection });
            //const data = await startChat('infos', { lang: appLanguage.slice(0, 2) });
            if (data.success) {
              // const obj = { role: 'assistant', content: getText('start') };
              // await discussWithChat(data.id, obj);
              localStorage.setItem('chatIdInfos', data.id);
            }
            console.log('chat initialised');
          } catch (error) {
            console.error(error);
          }
        };

        try {
          if (localStorage.getItem('chatIdInfos')) {
            const result = await getSummary(localStorage.getItem('chatIdInfos'));
            if (result.summary) {
              config.state.user = result.summary;
            }
            else {
              await getIdChat();
            }
          }
          else {
            await getIdChat();
          }
       
        } catch (error) {
          console.error(error);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const postPicture = async (target) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0];
        try {
          const result = await uploadPictureD_id(file);
          if (result.result_url) {
            document.getElementById('ai-conf').style.display = 'none';
          }
        }
        catch (e) {

        }
      }
    }
  }

  let avataeImage;
  let id;
  if (window.location.href.includes('id=')) {
    id = window.location.href.split('id=')[1].split('&')[0];
  } else {
    id = titleNormalized.includes('ybera') ? '2094429' : '2085475';
  }

  useEffect(() => {

    const getAvatar = async (id) => {
      localStorage.removeItem('avatar');
      localStorage.removeItem('video_intro');
      localStorage.removeItem('ai_video_duration');
      localStorage.removeItem('ai_text');
      localStorage.removeItem('mute');
      if (id) {
        try {
          const profile = await getProfile(id);
          if (profile) {
            if (profile.infos && profile.infos.ai_setting) {
              localStorage.setItem('avatar', profile.infos.ai_setting.ai_avatar);
              localStorage.setItem('video_intro', profile.infos.ai_setting.ai_video);
              localStorage.setItem('video_text', profile.infos.ai_setting.ai_text);
              localStorage.setItem('ai_video_duration', (((profile.infos.ai_setting.ai_video_duration || 0) * 1000)-500).toString());

              const link = document.createElement("link");
              link.rel = "prefetch";
              link.as = "video";
              link.type = "video/mp4";
              link.href = localStorage.getItem('video_intro');
              document.body.append(link);
            }
            if(profile.name) {
              document.querySelector('.react-chatbot-kit-chat-header').innerHTML = profile.name;
            }
          }
        } catch {

        }
      }
    }
    getAvatar(id);

  }, [id]);


  return (
    <div className="App">
        <Welcome
          data={data}
          handleChangeAdvisor={handleChangeAdvisor}
          advisor={advisor}
          handleReady={handleReady}>
        </Welcome>
      {chatReady && <Chatbot
        config={config}
        headerText={getAppName(titleNormalized)}
        messageParser={MessageParser}
        actionProvider={ActionProvider}
        placeholderText={getText('placeholder')}
        validator={(msg) => msg.length > 0}
      />}
      <div className={'chatId'}>
        {localStorage.getItem('chatIdInfos')}
      </div>
      {/*<div id={"ai-con"}>*/}
      {/*<img src="/assets/images/config.png" className={'setting'} onClick={ (e) => {document.getElementById('ai-conf').style.display = 'block'}}/>*/}
      {/*<video src={localStorage.getItem('MY_AI_TALKING_FACE') ? localStorage.getItem('MY_AI_TALKING_FACE') : "/assets/video1.mp4"} autoPlay={true} loop={true} id={"ai-video"}></video>*/}
      {/*<div id={'ai-conf'}>*/}
      {/*    <input accept="image/*"*/}
      {/*           type="file"*/}
      {/*           capture="environment"*/}
      {/*           onChange={ (e) => {postPicture(e.target, 25)}} />*/}
      {/*</div>*/}
      {/*</div>*/}
    </div>
  );
}

export default App;


