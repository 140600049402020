import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, Box, CircularProgress, Typography } from '@mui/material';
import { styled } from '@mui/system'; // Importez styled depuis '@mui/system'
import './popupAnalyse.css';
import { titleNormalized, appLanguage } from '../../select';

const CircleContainer = styled('div')({
  position: 'relative',
  width: '90px', // Largeur du cercle
  height: '90px', // Hauteur du cercle
});

const PercentageText = styled(Typography)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

const PopupAnalyse = ({ open, handleClose, fullScreen, num }) => {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setPercentage(prevPercentage => {
        const newPercentage = prevPercentage + 1;
        if(num === 1 && newPercentage === 30){ // ??why
          if(!titleNormalized.includes('hair')) {
            clearInterval(interval);
            handleClose()
          }
        }
        if (newPercentage === 100) {
          clearInterval(interval);
          setTimeout(handleClose, 1500)
        }
        return newPercentage;
      });
    }, 30); 

    return () => clearInterval(interval);
  }, []); 

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: {
            fontSize: '13px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '20px',
            fontFamily: 'customFont',
            maxHeight: '20%'
          },
        }}
      >
        <DialogContent >
          <Box display="flex" alignItems="center" justifyContent="space-around">
            <CircleContainer>
              <CircularProgress variant="determinate" value={percentage} size={90} thickness={4}  />
              <PercentageText variant="body1">{`${percentage}%`}</PercentageText>
            </CircleContainer>
            <Box>
            {num === 1 && <Typography>PHOTO N°1</Typography>}
            {num === 2 && <Typography>PHOTO N°2</Typography>}
           {percentage < 100 && <Typography>Analyse en cours</Typography>}
           {percentage === 100 && <Typography>Analyse terminée</Typography>}
           </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default PopupAnalyse;
