import axios from 'axios'

const base_url = 'https://gpt-api.gydtech.io/'

export const getRoutine = async(data, params) => {
  
    try {
      const response = await axios({
        method: "post",
        url: `${base_url}api/gptAdviceHair/hairRoutine`,
        timeout: 60000,
        headers: { "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_GPT_CONSEIL,
            "login": process.env.REACT_APP_LOGIN_CACTUS},
        params,
        data
      });
      console.log(response.data);
      
      return response.data ?  response.data : "Quelque chose s'est mal passée pour votre recommendation essayer ultérieurement"
    } catch (error) {
      if (error.code === 'ECONNABORTED') {
        console.log('Request timed out');
      }
      else 
        console.error(error)
        return  "Quelque chose s'est mal passée pour votre recommendation essayer ultérieurement"
    }
  }

export const sendRecommendationEmail = async(recommendation, params) => {
  
    try {
      const response = await axios({
        method: "post",
        url: `${base_url}api/sendMail/`,
        headers: { "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_GPT_CONSEIL,
            "login": process.env.REACT_APP_LOGIN_CACTUS},
        params,
        data: {
          recommendation
        }
      });
      
      return response.data
    } catch (error) {
      console.error(error)
    }
  }

export const sendEmail = async(receipts, templateId, params) => {

    try {
        const response = await axios({
            method: "post",
            url: `https://api.effimetrix.care/sendMailTemplate`,
            headers: { "Content-Type": "application/json"},
            data: {to: receipts, templateId, params}
        });

        return response.data
    } catch (error) {
        console.error(error)
    }
}
