import React, { useState, useEffect, useCallback} from 'react';
import { Button, Box } from '@mui/material';
import { createClientMessage } from 'react-chatbot-kit';
import './choices.css';
import { capitalizeFirstLetter } from '../../utils/utils';
import { WordsHelper } from "../../language";
import { appLanguage } from "../../select";
const getTextHelper = (key) => {
    return WordsHelper[key][appLanguage]
}


const Choices = (props) => {
    const { payload, actionProvider, setState, state } = props;
    let { choices, type, qt } = payload;


    choices = choices.map(value => {
        if (typeof value === 'boolean') {
            return value ? "Oui" : "Non";
        }
        return value
    });

    const [inputValue, setInputValue] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const [btnClicked, setBtnClicked] = useState();
    const [isDisabled, setIsDisabled] = useState(false);
    const color_hair = ['Auburn', 'Blonds', 'Bruns', 'Chatains', 'Roux', 'Gris _ Blanc ']
    const [loadedImages, setLoadedImages] = useState([]);
    const [longValue, setLongValue] = useState(1);
    const [hairTypeValue, setHairTypeValue] = useState(null);
    const [epsValue, setEpsValue] = useState(270);
    const [color, setColor] = useState(null);
    const [colorValue, setColorValue] = useState(50);
    const [mouseIsDown, setMouseIsDown] = useState(false);

    const hair_color = {
        '#382207' : 2,
        '#dddddd' : 4,
        '#FFEB00' : 0,
        '#8B4513' : 1,
        '#FF7F00' : 3
    }


    const handleImageLoad = (index) => {
        setLoadedImages(prevState => {
            const newState = [...prevState];
            newState[index] = true;
            return newState;
        });
    };


    useEffect(() => {
        input.readOnly = true
        const timeout = setTimeout(() => {
            setIsVisible(true);
        }, 3000); // 2000 millisecondes (2 secondes)

        return () => clearTimeout(timeout)
    }, []); //

    useEffect(() => {
        const timeout = setTimeout(() => {
            const lastChild = document.querySelector('.react-chatbot-kit-chat-message-container').lastChild
            lastChild.scrollIntoView()
        }, 1000);

        return () => clearTimeout(timeout)
    }, [isVisible]);

    const createChatUserMessage = (msg, options = {}) => {
        const message = createClientMessage(
            msg,
            options
        );
        setState(prevState => ({
            ...prevState,
            messages: [...prevState.messages, message]
        }))
    };

    const button = document.getElementsByClassName("react-chatbot-kit-chat-btn-send")[0];
    const input = document.getElementsByClassName("react-chatbot-kit-chat-input")[0];

    const handleEvent = (item) => {
       
        if (!item)
            item = input.value;
        
            
        if (typeof item === 'object' && item.btn === 'homme') {
            document.body.style.background = "url('/assets/images/background/man_back.gif') no-repeat fixed";
            input.style.background = 'linear-gradient(to right, #d5efff61, #d5efffad)';
        }
        else if (typeof item === 'object' && item.btn === 'femme')
            document.body.style.background = "url('/assets/images/background/woman_back.gif') no-repeat fixed";

        button.removeEventListener("click", handleButtonClick);
        // input.readOnly = false
        
        createChatUserMessage(item.text || item);
        actionProvider.next(item.text || item); 
        setIsDisabled(true)
    };

    const choseAge = () => {
        const selectBox = document.getElementById("selectBox");
        const selectedValue = selectBox.options[selectBox.selectedIndex].value;
        setInputValue(selectedValue);
    }

    const handleChoice = (item) => {
        if (!inputValue.includes((item.text || item))) {
            setBtnClicked(1);
            input.readOnly = true
            setInputValue((prevValue) =>
                prevValue ? prevValue + ', ' + (item.btn || item) : (item.text || item)
            )
        }

    };

    const handleButtonClick = useCallback(() => {
        handleEvent();
    }, []);

    const handleKeyDown = (event) => {
        setBtnClicked(2);
        // if (event.key === 'Enter') {
        // handleEvent();
        // }
    };

    const clickHandler = () => {
        handleButtonClick();
    };

    const keydownHandler = (event) => {
        handleKeyDown(event);
    };

   

    const getThumbColor = (value) => {
        let color;
    
        if (value <= 70) {
          color = '#382207'; //Brun
        } else if (value <= 140) {
          color = '#dddddd'; // Blanc
        } else if (value <= 210) {
          color = '#FFEB00'; // Blond 
        } else if (value <= 280) {
          color = '#8B4513'; // Chatain
        } else {
          color = '#FF7F00'; // Roux
        }
    
        return color;
      };

    const handleMouseMove = (e) => {
        if (e.type === "mousemove" && !mouseIsDown)
            return

        const longsImg = document.getElementById('longsImg');
        const newPosition = (longsImg.offsetWidth + (longsImg.getBoundingClientRect().left - (e.clientX || e.touches[0].clientX)));
        setLongValue(newPosition);
    };

    const isActive = (val) => {
        if (val <= 50) { return 3 }
        else if (val > 50 && val <= 170) { return 2 }
        else if (val > 170 && val <= 270) { return 1 }
        else if (val > 270) { return 0 }
    };

    const isActive2 = (val) => {
        if (val <= 70) { return 2 }
        else if (val > 70 && val <= 250) { return 1 }
        else if (val > 250) { return 0 }
    };

    useEffect(() => {
        input.value = inputValue
    }, [inputValue]); // The empty dependency array ensures this effect runs only once on mount

    // Attach event listeners when the component is mounted

    useEffect(() => {
        if (type === 'array') {
            // button.style.display = 'flex';
            button.addEventListener("click", handleButtonClick);
        } else {
          //  button.style.display = 'none';
        }
    }, []); // The empty dependency array ensures this effect runs only once on mount

    if (qt === 'Longueur') {
        return (
            isVisible && (<div style={{display: "flex", justifyContent: "space-evenly", position: "relative"}}>
                <div style={{
                    position: "relative",
                    outline: "none",
                    backgroundImage: "url(/assets/images/longs.jpg)",
                    backgroundSize: "cover",
                    backgroundPosition: "35%",
                    width: "216px",
                    height: "379px",
                    borderRadius: "10px"
                }}>
                </div>
                <div style={{display: "flex", marginBottom: 10, flexDirection: "column"}}>
                    {choices.map((item, index) => (
                        <span key={index} onClick={() => {setLongValue(index)}}
                              style={{
                            fontSize: 13, color: (longValue === index ? "#B7A7F2" : "#BFBFBF"), border: "1px #B7A7F2 solid", margin: "21px auto",
                            borderColor: (longValue === index ? "#B7A7F2" : "#BFBFBF"), borderRadius: "50px", padding: "7px 12px", position: "relative"
                        }}>
                            {capitalizeFirstLetter(item.btn || item.text) || capitalizeFirstLetter(item)}
                            <hr style={{position: "absolute", bottom: "-28px", width: "65vw", right: "-12px", border: 0, borderBottom: "1px #BFBFBF solid", height: "1px"}}/>
                        </span>
                    ))}
                </div>

                <button style={{border: '1px #B7A7F2 solid', color: '#B7A7F2', background: "transparent", position: "absolute", bottom: "-12px", right: 0}}
                        onClick={() => handleEvent(choices[longValue])} disabled={isDisabled}>Valider
                </button>
            </div>)
        )
    } else if (qt === 'Type') {
        return (
            isVisible && (
                <div className={'hair-types'}>
                    <div className={'hair-type-box'}>
                        <svg onClick={() => setHairTypeValue(0)}
                             style={{borderColor: (hairTypeValue === 0 ? '#B7A7F2' : '#BFBFBF')}}
                             xmlns="http://www.w3.org/2000/svg" width="4.076" height="138.768"
                             viewBox="0 0 4.076 138.768">
                            <clipPath>
                                <rect width="4.076" height="138.768"/>
                            </clipPath>
                            <g>
                                <path style={{stroke: (hairTypeValue === 0 ? state.color_hair : '#BFBFBF') }}
                                    d="M4.076,1C3.987,47.222,2.987,92.6,1,138.453"
                                    transform="translate(-0.5 -0.342)" />
                            </g>
                        </svg>
                        <svg onClick={() => setHairTypeValue(1)}
                            style={{ borderColor: (hairTypeValue === 1 ? '#B7A7F2' : '#BFBFBF') }}
                            xmlns="http://www.w3.org/2000/svg" width="16.865" height="142.287"
                            viewBox="0 0 16.865 142.287">
                            <clipPath>
                                <rect width="16.865" height="142.287" />
                            </clipPath>
                            <g>
                                <path style={{ stroke: (hairTypeValue === 1 ? state.color_hair : '#BFBFBF') }}
                                    d="M5.886,1C2.256,5.971,1.3,14.072,3.208,20.147,6.063,27.572,10.345,32.972,13.2,40.4c2.379,7.425.476,16.875-3.331,22.95A68.191,68.191,0,0,0,2.256,73.472c-1.9,3.375-1.428,8.1,0,11.475,4.758,11.475,12.372,19.575,14.275,33.075.952,8.1,0,15.525-1.428,22.95a4.447,4.447,0,0,1-.921.963"
                                    transform="translate(-0.525 -0.325)" />
                            </g>
                        </svg>
                        <svg onClick={() => setHairTypeValue(2)}
                            style={{ borderColor: (hairTypeValue === 2 ? '#B7A7F2' : '#BFBFBF') }}
                            xmlns="http://www.w3.org/2000/svg" width="21.578" height="139.679"
                            viewBox="0 0 21.578 139.679">
                            <clipPath>
                                <rect width="21.578" height="139.679" />
                            </clipPath>
                            <g>
                                <path style={{ stroke: (hairTypeValue === 2 ? state.color_hair : '#BFBFBF') }}
                                    d="M20.12,265.338c.055,0,.115.021.162,0,1.478-.752,1.754-2.648,2.077-4.21,1.737-8.395,1.159-16.451-2.567-23.908-1.612-3.226-3.654-5.621-6.853-5.659a4.445,4.445,0,0,0-2.612.829,3.5,3.5,0,0,0-.336,5.475c2.486,2.5,6.645,1.773,9.313-.227"
                                    transform="translate(-5.707 -126.123)" />
                                <path style={{ stroke: (hairTypeValue === 2 ? state.color_hair : '#BFBFBF') }}
                                    d="M47.527,92.579c-.065,8.159-2.126,16.995-6.811,22.768"
                                    transform="translate(-26.303 -50.425)" />
                                <path style={{ stroke: (hairTypeValue === 2 ? state.color_hair : '#BFBFBF') }}
                                    d="M21.123,34.133c-2.208,2.591-5.63,4.623-8.546,2.893-2.452-1.455-4.668-5.164-2.093-8.109,2.531-2.895,6.3-1.788,8.689.736,2.6,2.754,3.859,7.166,4.969,11.129a99.268,99.268,0,0,1,3.1,16.29"
                                    transform="translate(-6.03 -14.813)" />
                                <path style={{ stroke: (hairTypeValue === 2 ? state.color_hair : '#BFBFBF') }}
                                    d="M45.232,1A41.449,41.449,0,0,1,44.8,11.437c-.564,3.015-1.653,7.2-3.776,8.99"
                                    transform="translate(-26.5 -0.545)" />
                                <path style={{ stroke: (hairTypeValue === 2 ? state.color_hair : '#BFBFBF') }}
                                    d="M15.548,179.413c4.139-2.515,5.262-7.373,5.86-12.595a35.09,35.09,0,0,0,.313-5.452c-.259-5.992-.728-11.951-2.414-17.633a33.968,33.968,0,0,0-9.949-16.179,9.876,9.876,0,0,0-3.608-2.046c-1.473-.472-3.611,1.345-4.4,3.537a5.856,5.856,0,0,0,.966,5.684c2.763,3.461,7.372,3.2,10.78.392a12.187,12.187,0,0,0,1.148-1.249"
                                    transform="translate(-0.646 -68.32)" />
                            </g>
                        </svg>
                        <svg onClick={() => setHairTypeValue(3)}
                            style={{ borderColor: (hairTypeValue === 3 ? '#B7A7F2' : '#BFBFBF') }}
                            xmlns="http://www.w3.org/2000/svg" width="23.67" height="122.731"
                            viewBox="0 0 23.67 122.731">
                            <clipPath>
                                <rect width="23.67" height="122.731" />
                            </clipPath>
                            <g>
                                <path style={{ stroke: (hairTypeValue === 3 ? state.color_hair : '#BFBFBF') }}
                                    d="M46.645,84.871a43.625,43.625,0,0,1,4.409,6.592c2.262,4.637,2.09,9.727.96,14.77a33.538,33.538,0,0,1-3.507,9.267"
                                    transform="translate(-29.52 -36.25)" />
                                <path style={{ stroke: (hairTypeValue === 3 ? state.color_hair : '#BFBFBF') }}
                                    d="M41.573,19.738A28.967,28.967,0,0,0,43.167,1"
                                    transform="translate(-26.31 -0.427)" />
                                <path style={{ stroke: (hairTypeValue === 3 ? state.color_hair : '#BFBFBF') }}
                                    d="M32,194.651c1.1-2.506.879-5.451.612-8.037-1.1-10.638-6.005-16.365-12.461-16.885-1.423-.115-2.133,1.567-2.519,3.411a14.1,14.1,0,0,0,.7,7.875c1.246,3.259,4.187,3.592,6.541.787a65.409,65.409,0,0,0,3.744-5.5"
                                    transform="translate(-10.986 -72.493)" />
                                <path style={{ stroke: (hairTypeValue === 3 ? state.color_hair : '#BFBFBF') }}
                                    d="M28.835,132.9a29.151,29.151,0,0,1-3.912,5.768c-1.836,2-3.931,2.342-5.887.522s-2.71-4.994-2.671-8.4A15.657,15.657,0,0,1,16.954,126c1.209-3.653,3.139-4.629,5.43-2.046a63.929,63.929,0,0,1,9.333,12.891c1.751,3.339,1.8,6.622-.016,11.086-.956,2.348-2.021,4.587-3.036,6.876"
                                    transform="translate(-10.356 -52.326)" />
                                <path style={{ stroke: (hairTypeValue === 3 ? state.color_hair : '#BFBFBF') }}
                                    d="M16.911,59.384a20.657,20.657,0,0,1-7.966,7.879c-1.773,1.1-3.421.172-4.559-2.07A10.541,10.541,0,0,1,4.047,57.8c.7-2.017,2.608-3.407,4.232-3.177a12.732,12.732,0,0,1,8.3,4.226c.419.474.808.533,1.189-.248,3.377-6.936,4.281-15.944.7-23.8-2.823-6.188-9.231-11-13.738-9.225C2.378,26.5.966,29.123,1,32.715c.021,2.228.953,3.9,2.367,4.5,3.569,1.518,6.507-1.232,9.451-3.542a5.753,5.753,0,0,0,2.4-3.073"
                                    transform="translate(-0.632 -10.763)" />
                            </g>
                        </svg>
                        <svg onClick={() => setHairTypeValue(4)}
                            style={{ borderColor: (hairTypeValue === 4 ? '#B7A7F2' : '#BFBFBF') }}
                            xmlns="http://www.w3.org/2000/svg" width="24.122" height="130.378"
                            viewBox="0 0 24.122 130.378">
                            <clipPath>
                                <rect width="24.122" height="130.378" />
                            </clipPath>
                            <g>
                                <path style={{ stroke: (hairTypeValue === 4 ? state.color_hair : '#BFBFBF') }}
                                    d="M25.684,26.285c4.382-1.6,4.126-5.579,4.441-9.111a6.627,6.627,0,0,0-.224-2.709c-.349-.951-.721-2.09-1.8-2.4-1.114-.317-1.541.727-2.129,1.464-1.882,2.357-4.721,3.772-6.357,6.384"
                                    transform="translate(-6.705 -4.105)" />
                                <path style={{ stroke: (hairTypeValue === 4 ? state.color_hair : '#BFBFBF') }}
                                    d="M22.372,7.96c.879-2.987-1.915-3.729-3.411-4.748C15.545.885,11.783.022,7.784,2.462c-4.1,2.5-5.3,6.724-2.708,10.655.4.609.809,1.214,1.214,1.821"
                                    transform="translate(-1.268 -0.342)" />
                                <path style={{ stroke: (hairTypeValue === 4 ? state.color_hair : '#BFBFBF') }}
                                    d="M18.353,60.847c.607-1.517,1.254-3.019,1.811-4.554a5.26,5.26,0,0,0-.708-5.6c-1.2-1.654-1.824-3.61-3.817-4.744-4.7-2.676-11.06-.613-12.68,4.6-1.128,3.633-2.547,7.62,1.132,10.9"
                                    transform="translate(-0.588 -15.307)" />
                                <path style={{ stroke: (hairTypeValue === 4 ? state.color_hair : '#BFBFBF') }}
                                    d="M24.008,142.255a19.877,19.877,0,0,0,3.5-6.973c.514-1.762.864-3.352-1.06-4.582-1.043-.667-.817-1.686-.334-2.71,1.573-3.328,3.517-6.638-.539-9.773-.72-.557-.531-1.854.193-3,2.554-4.036,3.951-8.31,1.617-13.031-.23-.465-.056-1.186-.654-1.488-2.818-1.418-2.19-3.175-.976-5.505,1.907-3.661,1.695-7.773,1.588-11.8-.005-.2.086-.511-.013-.591-3.27-2.631-1.266-5.4-.317-8.216.9-2.668-.367-5.017-3.625-6.952-1.357-.807-2.822-1.432-4.239-2.14"
                                    transform="translate(-6.547 -22.389)" />
                                <path style={{ stroke: (hairTypeValue === 4 ? state.color_hair : '#BFBFBF') }}
                                    d="M24.738,164.6c-2.591,2.569-4.719,5.618-7.89,7.584"
                                    transform="translate(-5.759 -56.261)" />
                                <path style={{ stroke: (hairTypeValue === 4 ? state.color_hair : '#BFBFBF') }}
                                    d="M7.4,107.446C1.881,112.4,1.037,118.31,4.977,124.435a4.508,4.508,0,0,0,7.285,0q3.02-4.712,6.077-9.4a6.357,6.357,0,0,1,.9-.917"
                                    transform="translate(-0.867 -36.726)" />
                                <path style={{ stroke: (hairTypeValue === 4 ? state.color_hair : '#BFBFBF') }}
                                    d="M17.5,71.033a26.248,26.248,0,0,0-2.1,2.742,13.128,13.128,0,0,1-8.522,6.037,4.545,4.545,0,0,0-3.455,3c-.706,1.719-1.384,3.465-1.966,5.219-1.073,3.237-.309,4.6,3,6.059.621.273,1.216.6,1.823.906"
                                    transform="translate(-0.342 -24.28)" />
                                <path style={{ stroke: (hairTypeValue === 4 ? state.color_hair : '#BFBFBF') }}
                                    d="M18.482,39.774a10.628,10.628,0,0,0,.91-10.619c-2.154-3.981-8.426-7.636-12.726-6.873-2.1.373-3.457,2.338-4.28,4.441-.866,2.213.341,3.6,1.841,4.85,3.465,2.877,7.447,2.886,11.512,1.789a20.849,20.849,0,0,0,3.652-1.78"
                                    transform="translate(-0.717 -7.582)" />
                                <path style={{ stroke: (hairTypeValue === 4 ? state.color_hair : '#BFBFBF') }}
                                    d="M9.09,179.349a22.653,22.653,0,0,1-2.749,2.4,6.969,6.969,0,0,0-3.313,5.373l3.028,2.847"
                                    transform="translate(-1.035 -61.303)" />
                                <path style={{ stroke: (hairTypeValue === 4 ? state.color_hair : '#BFBFBF') }}
                                    d="M22.386,88.548c-.809.708-1.944,1.259-2.372,2.15-2.055,4.28-5.719,7.016-9.159,9.985"
                                    transform="translate(-3.71 -30.266)" />
                                <path style={{ stroke: (hairTypeValue === 4 ? state.color_hair : '#BFBFBF') }}
                                    d="M6.005,136.023c-.923,1.869-2.743,3.33-2.984,5.466-.315,2.787-.349,5.6,2.651,7.328.543.313,1.505.5,1.547,1.464"
                                    transform="translate(-0.985 -46.494)" />
                                <path style={{ stroke: (hairTypeValue === 4 ? state.color_hair : '#BFBFBF') }}
                                    d="M12.93,178.386a11.9,11.9,0,0,1,10.581,4.758,5.776,5.776,0,0,1-1.063,7.392"
                                    transform="translate(-4.42 -60.947)" />
                                <path style={{ stroke: (hairTypeValue === 4 ? state.color_hair : '#BFBFBF') }}
                                    d="M22.76,184.419l-6.373,6.371" transform="translate(-5.601 -63.036)" />
                                <path style={{ stroke: (hairTypeValue === 4 ? state.color_hair : '#BFBFBF') }}
                                    d="M20.8,145.7a43.051,43.051,0,0,0-3.013,3.353c-1.83,2.5-3.843,4.247-7.311,4.008-2.564-.176-3.068,2.225-3.625,4.169-1.112,3.878-1.145,7.455,3.024,9.705"
                                    transform="translate(-2.124 -49.802)" />
                                <path style={{ stroke: (hairTypeValue === 4 ? state.color_hair : '#BFBFBF') }}
                                    d="M10.855,107.907c3.28,2.065,7.265,2.369,10.621,4.247"
                                    transform="translate(-3.71 -36.883)" />
                                <path style={{ stroke: (hairTypeValue === 4 ? state.color_hair : '#BFBFBF') }}
                                    d="M16.848,135.1l6.979,4.854" transform="translate(-5.759 -46.179)" />
                                <path style={{ stroke: (hairTypeValue === 4 ? state.color_hair : '#BFBFBF') }}
                                    d="M19.073,53.519c-2.111.518-3.068,2.45-3.842,4a11.894,11.894,0,0,1-8.637,6.427c-3.582.683-3.113,3.4-4.183,5.368-1.046,1.923.407,3.209,1.486,4.539.229.282.409.6.611.907"
                                    transform="translate(-0.701 -18.293)" />
                                <path style={{ stroke: (hairTypeValue === 4 ? state.color_hair : '#BFBFBF') }}
                                    d="M16.868,196.189c-2.692-1.395-7.34-2.987-9.714-.153"
                                    transform="translate(-2.445 -66.469)" />
                                <path style={{ stroke: (hairTypeValue === 4 ? state.color_hair : '#BFBFBF') }}
                                    d="M19.615,156.3l5.766,3.034" transform="translate(-6.705 -53.426)" />
                            </g>
                        </svg>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", marginTop: 10 }}>
                        {choices.map((item, index) => (
                            <span key={index}
                                style={{ fontSize: 13, color: (hairTypeValue === index ? "#B7A7F2" : "#BFBFBF") }}>
                                {capitalizeFirstLetter(item.btn || item.text) || capitalizeFirstLetter(item)}
                            </span>
                        ))}
                    </div>
                    {<button style={{ border: '1px #B7A7F2 solid', color: '#B7A7F2', background: "transparent", marginTop: 20 }}
                        onClick={() => handleEvent(choices[hairTypeValue])} disabled={isDisabled ||  hairTypeValue === null}>Valider
                    </button>}
                </div>
            )
        )
    }
    else if (qt === 'Epaisseur') {
        return (
            <div className={'Epaisseur'}>
                <div style={{ position: "relative", width: 334, margin: "auto" }}>
                    <div style={{
                        position: 'absolute',
                        right: 0,
                        height: '23px',
                        background: '#f3fcff85',
                        width: epsValue + 'px'
                    }}></div>
                    <img src={'/assets/images/range.png'} id={'epsImg'} />
                </div>
                <input type="range" min={1} max={334} style={{ width: 334 }}
                    onInput={event => setEpsValue(334 - (+event.target.value))} />
                <div style={{ display: "flex", justifyContent: "space-between", marginTop: 10 }}>
                    {choices.map((item, index) => (
                        <span key={index}
                            style={{ fontSize: 13, color: (isActive2(epsValue) === index ? "#B7A7F2" : "#BFBFBF") }}>
                            {capitalizeFirstLetter(item.btn || item.text) || capitalizeFirstLetter(item)}
                        </span>
                    ))}
                </div>
                <button style={{ border: '1px #B7A7F2 solid', color: '#B7A7F2', background: "transparent", marginTop: 20 }}
                    onClick={() => handleEvent(choices[isActive2(epsValue)])} disabled={isDisabled}>Valider
                </button>
            </div>
        )
    } 
     else if (qt === 'Couleur') {
        return (
            <>
            <div className={'Longueur'} style={{ position: 'relative' }}>
                <img
                    src={'/assets/images/colorBar.png'}
                    alt="Color Bar"
                    style={{ width: '100%' }}
                />
                <input
                    type="range"
                    min={1}
                    max={334}
                    style={{
                        position: 'absolute',
                        left: 0,
                        bottom: 0,
                        width: '100%',
                        height: '100%',
                        appearance: 'none', /* Supprime l'apparence native */
                        background: 'transparent', /* Rend la piste transparente */
                        outline: 'none', /* Supprime la bordure de l'élément lorsqu'il est focus */
                    }}
                    onInput={(event) => {
                        const thumbColor = getThumbColor(event.target.value);
                        setColorValue((+event.target.value))
                        setColor(hair_color[thumbColor]);
                        console.log(color, choices[color])
                        setState(prevState => ({
                            ...prevState,
                            color_hair: thumbColor
                          }))
                      }}
                />
                  <style>
                {`
                .Longueur input[type=range]::-webkit-slider-thumb {
                    background: ${getThumbColor(colorValue)}; /* Utilisation de la fonction pour le fond du curseur */
                  } 
                `}
            </style>
            </div>
             { <button style={{ border: '1px #B7A7F2 solid', color: '#B7A7F2', background: "transparent", marginTop: '30px'}}
             onClick={() => handleEvent(choices[color])} disabled={isDisabled || color === null}>Valider
         </button>}
         </>
        )
    }
     else {
        return (
            isVisible && (
                <Box sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: (qt === 'genre' ? 'column' : 'row'),
                    padding: '0 5%',
                    margin: 'auto',
                    width: 'fit-content',
                    justifyContent: 'center',
                    animation: 'fade 2s steps(50, end)'
                }}>
                    {choices.map((item, index) => (
                        (item) === 'age' ?
                            <select id={'selectBox'} onChange={() => choseAge()} key={index}>
                                {Array.from(Array(121).keys()).map((age, ii) => <option key={ii}
                                    value={age}>{age}</option>)}
                            </select> :
                            <Button className='btnChoices' key={index} variant="outlined" disabled={isDisabled}
                                onClick={() => type === 'array' ? handleChoice(item) : handleEvent(item)}>
                                {item.img &&
                                    <img className={color_hair.includes(item.img) ? null : 'imgChoices'}
                                        src={`/assets/images/hair_icons/${item.img}.png`} width={'42'}
                                        alt={item.text || item}
                                        onLoad={() => handleImageLoad(index)} />}
                                {(loadedImages[index] || !item.img) && (capitalizeFirstLetter(item.btn || item.text) || capitalizeFirstLetter(item))}
                            </Button>
                    ))}
                </Box>
            )
        );
    }

};

export default Choices;
