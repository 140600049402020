import React, { useState, useEffect, useRef } from 'react'
import { Button, Box } from '@mui/material';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import './selfieOrChat.css'
import {uploadPicture, processAnalysis, skinType, updatefollowingChat} from '../../api/service.js'
import { saveResWidget } from '../../utils/utils';
import { getRequest, sortScoreArray } from '../../utils/helper';
import Pub from '../../components/pub/pub';
import { Langage, LangageHair } from "../../language";
import { appLanguage, titleNormalized } from "../../select";
import { getRoutine } from '../../api/api-conseil-hair';
import CircularProgress from '@mui/material/CircularProgress';
import {ChevronRight} from "@mui/icons-material";
import {uploadPic} from "../../api/service-hair";
import PopupAnalyse from '../../components/popupAnalyse/popupAnalyse.jsx';


const SelfieOrChat = (props) => {
    const { setState } = props

    const [takePic1, setTakePic1] = useState(false); // verifie si la photo 1 a etet rpis dans cactus hair
    const [takePic2, setTakePic2] = useState(false); // verifie si la photo 1 a etet rpis dans cactus hair
    const [openPub, setOpenPub] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [numPicture, setNumPicture] = useState(1);
    const [showFullText, setShowFullText] = useState(false);
    const [routine, setRoutine] = useState(null);
    const [afterLoading, setAfterLoading] = useState(false);
    const [buttonClicked, setButtonClicked] = useState(0);
    const [error, setError] = useState(null);
    const [imageSelfy, setImageSelfy] = useState('');
    const [showSeconBtn, setShowSecondBtn] = useState(false);

    const buttonRef1 = useRef(null);
    const buttonRef2 = useRef(null);
    const buttonRef3 = useRef(null);

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const request = getRequest(props.state);
    //             const routine = await getRoutine(request);
    //             setState(prevState => ({
    //                 ...prevState,
    //                 recommendationGPT: routine
    //             }))
    //             //const routineFormatted = routine.replace(/\n/g, "<br>");
    //             setRoutine(routine);
    //         } catch (error) {
    //             // Gérez les erreurs ici
    //             console.error(error);
    //         }
    //     };

    //     fetchData(); // Appelez la fonction asynchrone ici
    // }, []);

    const handleCloseDialog = () => {
        setOpenDialog(false)
    }

    useEffect(() => {
        if (showSeconBtn && buttonRef2.current) {
          buttonRef2.current.scrollIntoView();
           // const maDiv = document.getElementsByClassName('react-chatbot-kit-chat-message-container')[0];
            // maDiv.scrollTop = maDiv.scrollHeight;
        }
      }, [showSeconBtn]);

    useEffect(() => {
        setTimeout(() => {
            setAfterLoading(true);
        }, 2000);
    }, []);

    useEffect(() => {
        if (buttonRef3.current)
            buttonRef3.current.scrollIntoView();
        else if (buttonRef2.current && !buttonRef3.current)
            buttonRef2.current.scrollIntoView();

    }, [buttonClicked]);

    useEffect(() => {
        if (routine && takePic1 && takePic2)
            setTimeout(() => {
                setAfterLoading(true);
            }, 3000);
    }, [routine, takePic1, takePic2]);

    useEffect(() => {
        if(props.state.json_data?.img_before) {
            setTimeout(() => {
                props.actionProvider.createBotMessage('J\'ai bien reçu la photo de votre chevelure, merci.')
                props.actionProvider.next(null, 3);
            }, 3000)
        }
    }, [props.state.json_data?.img_before]);

    // recoit les scores et les formattes de la bonne facon pour les envoyer a l api
    const getText = (key) => {
        return Langage[key][appLanguage]
    }

    const extractScore = (arr) => {
        let scores = {}
        Object.keys(arr).forEach((key) => scores[key] = arr[key].final)
        return scores
    }

    const handleCapture = async (target) => {

        if (target.files && titleNormalized.includes('hair')) {
            if (target.files.length !== 0) {
                const file = target.files[0];
                // const newUrl = URL.createObjectURL(file);
                // setSource(newUrl);
                const tmp = { "imgSelfie": [...props.state.imgSelfie, file] }
                props.setState((prev) => {
                    return { ...prev, ...tmp }
                });
            }
            /* if (takePic1)
                props.actionProvider.next(5); */

            if (takePic1) {
                const uploadPicture = await uploadPic(target.files[0], props.state.token);
                await updatefollowingChat({first_image: 'https://marketplace.gydtech.io/skanmyskin/upload/' + uploadPicture.id}, localStorage.getItem("followingCactusId"));
               props.setState(prevState => ({
                    ...prevState,
                    json_data: {
                      ...prevState.json_data,
                      img_before: 'https://marketplace.gydtech.io/skanmyskin/upload/' + uploadPicture.id
                    }
                  }));
                  
            } else {
                //props.actionProvider.createBotMessage('J ai bien reçu votre photo du cuir chevelu, passons maintenant à une photo de l ensemble de la chevelure.')
                setTimeout(() => {
                    setNumPicture(2)
                    setShowSecondBtn(true)
                    setButtonClicked((prev) => prev + 1)
                    setTakePic1(true)
                }, 4500);
            }
            setOpenDialog(true)
        } else if (target.files && !titleNormalized.includes('hair')) {
            if (target.files.length !== 0 && buttonClicked > 2) {
                const file = target.files[0];
                const tmp = { "imgSelfie": file }
                props.setState((prev) => {
                    return { ...prev, ...tmp }
                });
                props.actionProvider.next(null, 2)
                
                setOpenDialog(true)
                await postPicture(file)
            }
        }
        // faire un post de la photo pour aue l api puisse rendre le type de peau en question et le stocker dans le state typeSkin
    }

    const postPicture = async (image) => {
        try {
            let result
            result = await uploadPicture(image)
            if (result.success) {
                setOpenPub(true);
                setImageSelfy(result.picture);

                const uid = result.uid
                const analysis = await processAnalysis(uid)
                const scores = extractScore(analysis.scores)
                const res = await skinType(scores, 50);
                const tmp = {
                    uid: uid,
                    analysis: analysis,
                    needs: sortScoreArray(analysis.scores),
                    myNeeds: sortScoreArray(analysis.scores).slice(-3),
                    skinType: res.skintype,
                    arg: res.arguments,
                    open: openPub
                }
                setError(null)
                setOpenPub(false);
                props.setState((prev) => {
                    return { ...prev, ...tmp }
                });
            }
            else {
                setOpenPub(false);
                setError("Une erreur est survenue avec cette image")
                throw new Error("Une erreur est survenue avec cette image");
            }
        } catch (err) {
            setOpenPub(false);
            console.error(err);
        }
    };

    return (<div className="btns">
        {!titleNormalized.includes('hair') && !titleNormalized.includes('ns') && <Button
            component="label" variant="contained" startIcon={<ChevronRight/>}
            onClick={() => {
                props.actionProvider.next(null, 2)
            }}
        >
            {getText('skip')}
        </Button>}
        <Button
            ref={buttonRef1}
            component="label"
            variant="contained"
            startIcon={<PhotoCameraIcon />}
        >
            {titleNormalized.includes('hair') && getText('take_selfie_scalp')}
            {!titleNormalized.includes('hair') && getText('left_profil')}
            <input
                hidden
                accept="image/*"
                type="file"
                onChange={(e) => handleCapture(e.target)}
            />
        </Button>
        {openDialog && <PopupAnalyse open={openDialog} handleClose={handleCloseDialog} num={numPicture}></PopupAnalyse>}
        {
            buttonClicked > 0 && <div className="react-chatbot-kit-chat-bot-message"><span>J ai bien reçu votre photo du cuir chevelu, passons maintenant à une photo de l ensemble de la chevelure.</span><div className="react-chatbot-kit-chat-bot-message-arrow"></div></div>
        }
        {showSeconBtn > 0 && <Button
            ref={buttonRef2}
            component="label"
            variant="contained"
            startIcon={<PhotoCameraIcon />}
        >
            {titleNormalized.includes('hair') && getText('take_selfie')}
            {!titleNormalized.includes('hair') && getText('right_profil')}
            <input
                hidden
                accept="image/*"
                type="file"
                onChange={(e) => {
                    if (!titleNormalized.includes('hair')) {
                        setButtonClicked((prev) => prev + 1);
                        if (takePic1) {
                            props.actionProvider.next(null, 3);
                        }
                    } else {
                        handleCapture(e.target)
                    }
                }}
            />
        </Button>
        }
        {buttonClicked > 2 && <Button ref={buttonRef3} component="label" variant="contained" startIcon={<PhotoCameraIcon />}>
            {getText('take_selfie')}
            <input hidden
                accept="image/*,text/plain"
                type="file"
                onChange={(e) => {
                    handleCapture(e.target)
                }} />
        </Button>
        }

        {/* demande une deuxime photo du crane si on est dans cactus hair */}
        {/* {takePic1 && <Button component="label" variant="contained" startIcon={<PhotoCameraIcon />}>
            {getText('take_selfie_scalp')}
            <input hidden
                accept="image/*"
                type="file"
                capture="environment"
                onChange={(e) => {
                    saveResWidget('Prendre mon selfie', setState); setState((prev) => {
                        return { ...prev, ...{ selfie: true } }
                    }); handleCapture(e.target)
                }} />
        </Button>}
        {((takePic2 && routine && !afterLoading) || (takePic2 && !routine)) &&
            <div className="react-chatbot-kit-chat-bot-message">
                <span>{getText('routin')}</span>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress color="primary" size={30} thickness={5} />
                </div>
            </div>} */}
        {error && <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                whiteSpace: 'pre-wrap'
            }}
        >
            <div>
                <span className="react-chatbot-kit-chat-bot-message">
                    {error && (
                        error
                    )}
                </span>
            </div>
        </Box>}
        <Pub open={openPub} image={imageSelfy}>
        </Pub>
    </div>)
}

export default SelfieOrChat
